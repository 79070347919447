import React from "react";
import Footer from "../views/components/FooterComponent";
import Navbar from "../components/Navbar/Navbar";
import "../assets/styles/layouts/layout.css";

const SimpleLayout = (props) => {
  const { eventPhoto, children } = props;
  return (
    <div className="root">
      <div className="navbar">
        <Navbar logo={eventPhoto} />
      </div>

      <div className="layout-mainPanel">
        <div className="layout-mainContent">{children}</div>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default SimpleLayout;
