/* eslint-disable no-unused-expressions */
import React from "react";
import { useTabs, TabPanel } from "react-headless-tabs";
import moment from "moment";
import { Button } from "@mui/material";
import { TabSelector } from "../common/Tabs/TabsSelector";
import EventsListComponent from "./EventsListComponent";
import { formatEvents } from "../../../helpers/helperMethods";
import "../../../assets/styles/components/card.css";

export default (props) => {
  const { eventsData, ...rest } = props;

  const formattedEventsData = formatEvents(eventsData).sort(
    (a, b) => b.categoryEvents.length - a.categoryEvents.length
  );
  // [formattedEventsData[0], formattedEventsData[1]] = [formattedEventsData[1], formattedEventsData[0]];
  const upcomingEvents = eventsData
    .filter((elt) => moment().format("L") === moment(elt.endDate).format("L"))
    .reverse()
    .map((elt) => ({
      id: elt.id,
      name: elt.name,
      venue: elt.venue,
      startDate: elt.startDate,
      endDate: elt.endDate,
      description: elt.description || "",
      paymentRequired: elt.payment ? elt.payment.required : false,
      organization: elt.organizer,
      category: elt.category,
      imgUrl: elt.coverPhoto,
    }));

  const tabs = formattedEventsData.map((elt) => elt.categoryName);
  const [selectedTab, setSelectedTab] = useTabs([
    "allEvents",
    "upComingEvents",
    ...tabs,
  ]);

  const onChange = (text) => setSelectedTab(text);
  const [visible, setVisible] = React.useState(8);

  const showMore = () => {
    setVisible((prevValue) => prevValue + 4);
  };

  return (
    <div className="events-root">
      <nav className="tabs-nav">
        <TabSelector
          isActive={selectedTab === "allEvents"}
          onClick={() => onChange("allEvents")}
        >
          All Events
        </TabSelector>

        <TabSelector
          isActive={selectedTab === "upComingEvents"}
          onClick={() => onChange("upComingEvents")}
        >
          Upcoming Events
        </TabSelector>
        {tabs.map((elt, index) => (
          <TabSelector
            key={index}
            isActive={selectedTab === elt}
            onClick={() => onChange(elt)}
          >
            {elt}
          </TabSelector>
        ))}
      </nav>
      <TabPanel hidden={selectedTab !== "allEvents"}>
        {formattedEventsData.map((evt) => {

          const sortedData = evt.categoryEvents.sort(
            (a, b) => new Date(a.endDate) - new Date(b.endDate)
          );

          const pending = [];
          const finished = [];
          sortedData.map((item) =>(moment().format("L") ===
          moment(item.endDate).format("L")
        ? pending.unshift(item)
        : finished.unshift(item))
          );
          const data = pending.concat(finished);
          return (
            evt.categoryEvents.length > 0 && (
              <div className="event-container" key={evt.categoryName}>
                <p className="not-centered-title">{evt.categoryName}</p>
                <EventsListComponent
                  content={data.slice(0, visible)}
                  {...rest}
                />
                <div style={{ textAlign: "center" }}>
                  {sortedData.length >= 6 && (
                    <Button
                      onClick={showMore}
                      sx={{
                        backgroundColor: "#044368",
                        textTransform: "none",
                        color: "white",
                        width: "200px",
                        "&:hover": {
                          backgroundColor: "#044368",
                          textTransform: "none",
                          color: "white",
                          width: "200px",
                        },
                      }}
                    >
                      {" "}
                      See More
                    </Button>
                  )}
                </div>
              </div>
            )
          );
        })}
      </TabPanel>

      <TabPanel hidden={selectedTab !== "upComingEvents"}>
        <div className="event-container">
          <EventsListComponent content={upcomingEvents} {...rest} />
        </div>
      </TabPanel>

      {formattedEventsData.map((evt) => {
        const sortedData = evt.categoryEvents
          .sort((a, b) =>
            new Date(a.endDate).getTime() > new Date(b.endDate).getTime()
              ? 1
              : new Date(b.endDate).getTime() >
                new Date(a.endDate).getTime()
              ? -1
              : 0
          )
        const pending = [];
        const finished = [];
        sortedData.filter((item) =>
          moment().format("L") === moment(item.endDate).format("L")
            ? pending.unshift(item)
            : finished.unshift(item)
        );
        const data = pending.concat(finished);
        return (
          <TabPanel
            key={evt.categoryName}
            hidden={selectedTab !== evt.categoryName}
          >
            <div className="event-container">
              <p className="not-centered-title">{evt.categoryName}</p>

              <div className="events">
                <EventsListComponent content={data} {...rest} />
              </div>
            </div>
          </TabPanel>
        );
      })}
    </div>
  );
};
