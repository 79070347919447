import React, { useEffect } from "react";
import { Grid, Typography, Card, Container, Button } from "@mui/material";
import { useHistory } from "react-router";
import logo from "../../assets/images/nelogo.png";

export default () => {
  const history = useHistory();
  const query = new URLSearchParams(window.location.search);
  useEffect(() => {
    const timer = setTimeout(() => {
      const tx_ref = query.get("tx_ref");
      const redirect = `${query.get("returnUrl")}`;
      const returnTo = redirect ? `${redirect}?tx_ref=${tx_ref}` : "/";
      history.push(returnTo);
    }, 5000);
    return timer;
  }, []);

  return (
    <Container maxWidth="sm">
      <Grid
        container
        justifyContent="center"
        direction="column"
        alignItems="center"
        mt={4}
      >
        <img
          src={`${logo}`}
          width="80px"
          height="80px"
          style={{ borderRadius: "50%" }}
        />
        <Typography variant="h4" align="center">
          Noneho Payment Confiramtion
        </Typography>
        <Typography variant="p" align="center">
          {query.get("message") ||
            "Your payment was received, you will be notified soon after processing your transaction"}
        </Typography>
        <br></br>
        <Typography
          variant="p"
          color="primary"
          onClick={() => history.push(query.get("returnUrl") || "/")}
        >
          Redirecting to original page in 4 seconds...
        </Typography>
      </Grid>
    </Container>
  );
};
