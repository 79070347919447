import { CircularProgress } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    padding: "35vh 0",
    textAlign: "center",
    width: "100%",
  },
  loader: {
    display: "inline-block",
    padding: 30,
    color: "#1876D1",
  },
});

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress className={classes.loader} />
      <p>Generating your tickets please wait...</p>
    </div>
  );
};
