import { Button, Typography } from "@mui/material";
import React, { useState } from "react";
import "../../../assets/styles/components/card2.css";
import PulseLoader from "react-spinners/PulseLoader";
import moment from "moment";
import Toast from "../../../components/MessageToast";
import ReactShare from "../../../components/ReactShare";
import NoContent from "../../../components/NoContent";

export default ({ content, onVote, voting, eventData, eventEnded }) => {
  const links =
    content.details && content.details.socialMedia
      ? Object.values(content.details)
      : [""];
   return  (
    <div>
      <div className={"cardRoot"}>
        <div
          style={{
            height: content.height || "fit-content",
          }}
          id={"cardImg"}
        > <img  src={content.photo || `https://via.placeholder.com/500x450.png?text=${
                content.name || content.firstName
              }`} style={{width:"100%"}}/></div>

        <div className={"cart-elt-2"}>
          <div className="description-container">
            <div>
              <Typography variant="h6">
                {content.name || `${content.firstName} ${content.lastName}`}
              </Typography>
              <Typography variant="subtitle1">Votes: {content.votes }</Typography>
              <br />
              {content.contestantCode ? (
                <Typography variant="subtitle1">
                  Contestant Number: {content.contestantCode}
                </Typography>
              ) : null}
              <br />
              <Typography variant="subtitle1"> {content.description}</Typography>
              <br />
            </div>
               <div className="contestant-qrCode">
                {content.candidateQrCodeUrl && <img src={content.candidateQrCodeUrl } alt="contestant bar code" style={{width:"100%"}}/>}
            </div>
          </div>
          {links[0] !== "" && (
            <div>
              
              <Typography variant="subtitle2">
                Contestant social medias
              </Typography>
              <br />
              {/* <FollowAt iconSize={2} links={links} /> */}
            </div>
          )}
          {!eventEnded && (
            <ReactShare
              link={`https://events.noneho.com/${eventData.id}/${
                content.id || content.candidateId
              }`}
              title={eventData.name}
              message={`Vote for contestant: ${content.firstName} ${content.lastName}`}
            />
          )}

          <Button
            onClick={() => onVote(content)}
            disabled={eventEnded}
            style={{
              display: "inline",
              padding: "15px",
              backgroundColor: eventEnded ? "#5D5C62" : "#1876D1",
              color: "#fff",
              marginTop: "5vh",
            }}
            sx={{ width: { sm: "80%", xs: "100%" } }}
          >
            {voting ? (
              <PulseLoader
                size={7}
                margin={3}
                color={"#fff"}
                loading={voting}
              />
            ) : (
              "Vote"
            )}
          </Button>
          <div className="contestant-qrCode-mobile">
              <img src={content.candidateQrCodeUrl} alt="contestant bar code" style={{width:"100%"}}/>
            </div>
        </div>
      </div>
    </div>
  );
};
