import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { makeStyles } from "@mui/styles";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DoneIcon from '@mui/icons-material/Done';
import { Chip } from '@mui/material';
import "../assets/styles/layouts/layout.css";


const useStyles = makeStyles((theme) => ({
  menu:{
    paddingRIght:"50px !important"
  }
}));

export default function SelectTicket({ getTicket, ticketType, price, tierPlaces, selectedTickets, getSelectedTickets, currency,defaultNumTickets,stopOnline}) {

  const classes = useStyles();

 const [Oneticket, setOneTicket] = React.useState({numberofTickets: 0});

  const handleChange = (event) => {
    const newTicket = {
      numberofTickets: +event.target.value,
      ticketType,
      Price:price[0],
      currency,
    };
    setOneTicket(newTicket);

    let tickets = selectedTickets;
    
    if (!tickets.map(t => t.ticketType).includes(ticketType)) tickets.push(newTicket);
    else tickets = tickets.map(t => t.ticketType === ticketType ? newTicket : t);
    
    getSelectedTickets(tickets.filter(t => t.numberofTickets !== 0));
    getTicket(Oneticket);
  };

  return (
    <div style={{position:"relative"}}>
      <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
        <Typography sx={{color:"rgba(0,0,0,0.52)"}}>{ticketType}</Typography>
        <Box sx={{display:"flex", padding:"20px", width: "40%", justifyContent:"space-between",alignItems:"center"}}>
            <Typography sx={{width: "200px", fontSize:"15px", color:"rgba(0,0,0,0.72)"}}>{price} {currency}</Typography>
          <FormControl fullWidth sx={{paddingRight:"20px"}}>
            <Select
              value={Oneticket.numberofTickets}
              onChange={handleChange}
              sx={{width:"100%", height:"40px !important", "@media(max-Width:600px)":{ width:"100%", marginLeft:"0px"},marginLeft:"0px"}}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              disabled={(stopOnline || tierPlaces === 0) && "disabled"}
            >
              <MenuItem value={0}>0</MenuItem>
              <MenuItem className={classes.menu} value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
        {!stopOnline && tierPlaces > 0 ? <Chip size="small" label={`Tickets Available`} color='success' sx={{position:"absolute", top:50}} variant="outlined"/> :<Chip size="small" label={`Sold Out`} color='error' sx={{position:"absolute", top:50}} variant="outlined"/>}
      <Divider sx={{marginBottom:"0px"}}/>
    </div>
  );
}