import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import AppRoute from "./routes/index";
import configureStore from "./redux/configureStore";

const store = configureStore();

const App = () => {
  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);
  return (
    <Provider store={store}>
      <PayPalScriptProvider
        options={{
          "client-id": process.env.PAYPAL_CLIENT_ID,
          intent: "capture",
        }}
      >
        <AppRoute />
      </PayPalScriptProvider>
    </Provider>
  );
};

export default App;
