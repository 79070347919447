import React, { useState } from "react";
import moment from "moment";
import VerticalImageCard from "../../../components/Cards/verticalImageCard";
import Toast from "../../../components/MessageToast";
import SearchComponent from "../../../components/CandidatesSearch";

export default ({
  eventInfo,
  votedCandidate,
  updatingVotes,
  loading,
  eventEnded,
  onVote,
  ...rest
}) =>(
  <div className="contestants-root">
    <p className="title"> All Contestants</p>
    <SearchComponent
      candidates={
        eventInfo.categorized
          ? eventInfo.candidates
              .map((el) => el.categoryCandidates)
              .reduce((prev, next) => prev.concat(next))
          : eventInfo.candidates
      }
      eventId={eventInfo.id}
    />
    {eventInfo && eventInfo.categorized ? (
      eventInfo.candidates.map((elt) =>  (
        <div key={elt.categoryName} className="contestant-container">
          <p className="not-centered-title">{elt.categoryName}</p>
          <div className="contestants">
            {elt.categoryCandidates
              .sort((a, b) => ((b && b.votes) || 0) - ((a && a.votes) || 0))
              .map((categoryCandidates) =>(
                <VerticalImageCard
                  eventEnded={eventEnded}
                  key={categoryCandidates.candidateId}
                  isListElt
                  onVote={onVote}
                  event={eventInfo}
                  content={categoryCandidates}
                  votedCandidate={votedCandidate}
                  updatingVotes={updatingVotes}
                  {...rest}
                />
              ))}
          </div>
        </div>
      ))
    ) : (
      <div className="contestant-container">
        <div className="contestants">
          {eventInfo &&
            eventInfo.candidates
              .sort((a, b) => b.votes - a.votes)
              .map((categoryCandidates) => (
                <VerticalImageCard
                  eventEnded={eventEnded}
                  onVote={onVote}
                  key={categoryCandidates.candidateId}
                  isListElt
                  event={eventInfo}
                  content={categoryCandidates}
                  votedCandidate={votedCandidate}
                  updatingVotes={updatingVotes}
                  {...rest}
                />
              ))}
        </div>
      </div>
    )}
  </div>
);
