import React, { Component } from "react";
import AppLayout from "../../layouts/AppLayout";
import EventContainer from "../containers/events/EventContainer";
import EventsContainer from "../containers/events/EventsContainer";

export class Event extends Component {
	render() {
		const { eventId } = this.props.match.params;

		if (eventId === "ShinningStarsAfricaAwards20231687441109404") {
			window.location.assign("https://vote.shiningstarsafrica.com/");
		}

		if (eventId === "ZikomoAfricaAwards20231692952330956") {
			window.location.assign("https://vote.zikomoawards.com/");
		}

		return eventId ? (
			<AppLayout>
				<EventContainer eventId={eventId} />
			</AppLayout>
		) : (
			<AppLayout>
				<EventsContainer />
			</AppLayout>
		);
	}
}

export default Event;
