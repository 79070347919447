import React, { useState, useEffect } from "react";
import { TextField, Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import SubmitButton from "../../../components/SubmitButton";

const useStyles = makeStyles((theme) => ({
  root: {
    "@media (max-width: 600px)": {
      padding: "10px 2%",
    },
  },
  inputElt: {
    padding: "10px 0",
    width: "100%",
    display: "grid",
  },
  groupInputElt: {
    padding: "10px 0",
    display: "grid",
    gridGap: "10px",
    gridTemplateColumns: "repeat(2,1fr)",

    "@media (max-width: 600px)": {
      gridTemplateColumns: "repeat(1,1fr)",
      padding: "5px 0",
    },
  },
  selectTitle: {
    color: "grey",
  },
  errorMsg: {
    color: "red",
  },
}));

export default ({
  message = "",
  onPaymentSubmit,
  loading = false,
  error,
  completed,
  showPaypal,
  tx_ref,
  description,
  onApprovePaypalOrder,
  onCancelPaypalOrder,
  onErrorPaypalOrder,
  currency,
  amount,
  email,
  phoneNumber,
}) => {
  const classes = useStyles();
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
  const [state, setState] = useState({});

  useEffect(() => {
    if (currency !== "RWF") {
      dispatch({
        type: "resetOptions",
        value: {
          ...options,
          currency,
        },
      });
    }
  }, [state.currency]);

  const onPayment = () => {
    if (
      !amount ||
      !email ||
      !email.includes("@") ||
      !email.includes(".") ||
      !currency
    ) {
      return setState({
        ...state,
        formError: "Please enter correct email and select amount",
      });
    }
    if (currency === "RWF") {
      return setState({
        ...state,
        formError: "Currency RWF is not supported by PayPal",
      });
    }
    return onPaymentSubmit({
      email,
      phoneNumber,
      amount,
      currency,
    });
  };

  return (
    <Box className={classes.root}>
      {completed && !error ? (
        <Box
          style={{
            textAlign: "center",
            width: "100%",
          }}
        >
          <Typography variant="subtitle1">
            Your payment is being processed, we will notify you once done.
          </Typography>
          <CircularProgress
            style={{
              display: "inline-block",
              padding: 30,
              color: "#1876D1",
            }}
          />
        </Box>
      ) : showPaypal ? (
        <PayPalButtons
          createOrder={(data, actions) =>
            actions.order.create({
              purchase_units: [
                {
                  custom_id: tx_ref,
                  reference_id: tx_ref,
                  description,
                  amount: {
                    value: amount,
                    currency_code: currency,
                  },
                },
              ],
            })
          }
          onApprove={(data, actions) =>
            actions.order.capture().then((details) => {
              onApprovePaypalOrder();
            })
          }
          onCancel={(data) => {
            onCancelPaypalOrder();
          }}
          onError={(err) => {
            onErrorPaypalOrder();
          }}
        />
      ) : (
        <>
          {state.formError || error ? (
            <Typography className={classes.errorMsg} variant="subtitle1">
              {state.formError || (error && message)}
            </Typography>
          ) : (
            <Typography variant="subtitle1">{message}</Typography>
          )}

          <SubmitButton
            onClick={onPayment}
            loading={loading}
            disabled={loading}
          >
            Proceed With Payment
          </SubmitButton>
        </>
      )}
    </Box>
  );
};
