import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import "react-phone-input-2/lib/material.css";
import { Formik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import { makeStyles } from "@mui/styles";
import SubmitButton from "../../../components/SubmitButton";

const useStyles = makeStyles(() => ({
  root: {
    padding: "30px 0",
    "@media (max-width: 600px)": {
      padding: "10px 2%",
    },
  },
  inputElt: {
    padding: "10px 0",
    width: "100%",
    display: "grid",
  },
  groupInputElt: {
    padding: "10px 0",
    display: "grid",
    gridGap: "10px",
    gridTemplateColumns: "repeat(2,1fr)",
    "@media (max-width: 600px)": {
      gridTemplateColumns: "repeat(1,1fr)",
      padding: "5px 0",
    },
  },
  selectTitle: {
    color: "grey",
  },
  submitBtn: {
    padding: "15px 0",
    width: "50%",
    backgroundColor: "#1876D1",
    textAlign: "center",

    "&:hover": {
      background: "#1876D1",
      cursor: "pointer",
    },
    "@media (max-width: 600px)": {
      padding: "10px 0",
      width: "100%",
    },
  },
  errorMsg: {
    color: "red",
  },
}));

export default ({
  showOTP,
  message = "",
  onPaymentSubmit,
  onOTPSubmit,
  loading = false,
  error,
  completed,
}) => {
  const classes = useStyles();
  const [state, setState] = useState({
    otp: null,
  });
  const onPhoneChange = (phone) => {
    setState({
      ...state,
      phoneNumber: phone,
      formError: "",
    });
  };

  const onInputChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
      formError: "",
    });
  };

  const onSubmitOtp = () => {
    onOTPSubmit(state.otp);
  };

  useEffect(() => {
    if (error || completed) {
      setState({
        otp: null,
        phoneNumber: null,
      });
    }
  }, [error, completed]);


  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  }

  return (
    <Box>
      {showOTP ? (
        <Box className={classes.inputElt}>
          <Typography variant="subtitle1">
            Please enter the MOMO validation OTP sent to you via SMS and
            Whatsapp to complete this transaction.
          </Typography>
          <br></br>
          <TextField
            id="otp"
            label="OTP"
            placeholder="Enter your OTP"
            name="otp"
            onChange={onInputChange}
            value={state.otp}
          />
          <br></br>
          <Typography variant="subtitle1" color="#1876D1">
            {error && message}
          </Typography>
          <SubmitButton
            onClick={onSubmitOtp}
            loading={loading}
            disabled={loading}
          >
            Send OTP
          </SubmitButton>
        </Box>
      ) : completed ? (
        <Box
          style={{
            textAlign: "center",
            width: "100%",
          }}
        >
          <Typography variant="subtitle1">
            Complete payment on your phone to be proccessed, we will notify you
            once done. If you do not see the prompt dial *182*7*1#
          </Typography>
          <CircularProgress
            style={{
              display: "inline-block",
              padding: 30,
              color: "#1876D1",
            }}
          />
        </Box>
      ) : (
        <React.Fragment>
          <Box className={classes.inputElt}>
            {state.formError || error ? (
              <Typography className={classes.errorMsg} variant="subtitle1">
                {state.formError || (error && message)}
              </Typography>
            ) : (
              <Typography variant="subtitle1">{message}</Typography>
            )}
          </Box>
          <Formik initialValues={{...initialValues}}
          validationSchema={Yup.object().shape({
          firstName: Yup.string().required("first Name is required"),
          lastName: Yup.string().required("last Name is required"),
          email: Yup.string().email('please proviide a valid email').required("email is required"),
          // phoneNumber: Yup.number().required('phone number is required'),
        })}
        onSubmit={(values)=> {
          if (!state.phoneNumber || state.phoneNumber.length < 12) {
            return setState({
              ...state,
              formError: "Please enter correct phone number"
            });
          }
          return onPaymentSubmit({
            firstName: values.firstName.trim(),
            lastName: values.lastName.trim(),
            email: values.email.trim(),
            phoneNumber: state.phoneNumber.replaceAll(/\s/g, ''),
          })
        }
        }
        >
          {({
            errors, handleBlur, handleChange, handleSubmit, touched, values
          }) =>(
            <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Box sx={{
              '& > :not(style)': { m: 1},
            }}>
              <TextField id="outlined-basic" error={Boolean(touched.firstName && errors.firstName)} helperText={touched.firstName && errors.firstName} name="firstName" required onBlur={handleBlur} value={values.firstName.trim()}
                onChange={handleChange}  type="text" label="First Name" variant="outlined" sx={{width:"100%", margin:"0px 10px"}}/>
              <TextField id="filled-basic" required value={values.lastName.trim()} error={Boolean(touched.lastName && errors.lastName)} helperText={touched.lastName && errors.lastName}  name="lastName" label="lastName" onBlur={handleBlur}
                onChange={handleChange} type="text" variant="outlined" sx={{width:"100%", margin:"0px 10px"}} />
              <TextField id="standard-basic" type="email" error={Boolean(touched.email && errors.email)} helperText={touched.email && errors.email} value={values.email.trim()} sx={{width:"100%", margin:"0px 10px"}}  name="email" onBlur={handleBlur}
              onChange={handleChange} label="Email" required variant="outlined" />
              <PhoneInput
                country={"rw"}
                className={classes.inputElt}
                value={values.phoneNumber.trim()}
                onChange={onPhoneChange}
                inputStyle={{ width: "100%" }}
              />
            </Box>
            <Box sx={{width:"100%", display:"flex", justifyContent:"center", margin:"10px 0px"}}>
              <SubmitButton type="submit" loading={loading} disabled={loading} sx={{
                padding: "15px 0",
                width: "50%",
                backgroundColor: "#1876D1",
                color: "white",
                display: "inline",
                "&:hover": {
                  background: "#1876D1",
                  cursor: "pointer",
                },

                "@media (max-width: 600px)": {
                  padding: "10px 0",
                  width: "100%",
                },
              }}>Proceed With Payment</SubmitButton>
            </Box>
            
        </Box>
          )}
        </Formik>
        </React.Fragment>
      )}
    </Box>
  );
};
