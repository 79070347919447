import React, { useState, useEffect } from "react";
import { TextField, Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import SubmitButton from "../../../components/SubmitButton";

const useStyles = makeStyles((theme) => ({
  root: {
    "@media (max-width: 600px)": {
      padding: "10px 2%",
    },
  },
  inputElt: {
    padding: "10px 0",
    width: "100%",
    display: "grid",
  },
  groupInputElt: {
    padding: "10px 0",
    display: "grid",
    gridGap: "10px",
    gridTemplateColumns: "repeat(2,1fr)",

    "@media (max-width: 600px)": {
      gridTemplateColumns: "repeat(1,1fr)",
      padding: "5px 0",
    },
  },
  selectTitle: {
    color: "grey",
  },
  errorMsg: {
    color: "red",
  },
}));

export default ({
  paymentOptions = {},
  message = "",
  onPaymentSubmit,
  loading = false,
  error,
  completed,
  showPaypal,
  tx_ref,
  description,
  onApprovePaypalOrder,
  onCancelPaypalOrder,
  onErrorPaypalOrder,
}) => {
  const classes = useStyles();
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
  const [state, setState] = useState({
    currency: paymentOptions.currency[0],
    options: paymentOptions.options[paymentOptions.currency[0]],
  });

  useEffect(() => {
    if (state.currency !== "RWF") {
      dispatch({
        type: "resetOptions",
        value: {
          ...options,
          currency: state.currency,
        },
      });
    }
  }, [state.currency]);

  const onAmountChoosing = (e) => {
    const { value: amount } = e.target;
    setState({
      ...state,
      amount,
      formError: "",
    });
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "currency") {
      setState({
        ...state,
        [name]: value,
        options: paymentOptions.options[value],
        formError: "",
      });
    } else {
      setState({
        ...state,
        [name]: value,
        formError: "",
      });
    }
  };

  const onPayment = () => {
    if (
      !state.amount ||
      !state.email ||
      !state.email.includes("@") ||
      !state.email.includes(".") ||
      !state.currency
    ) {
      return setState({
        ...state,
        formError: "Please enter correct email and select amount",
      });
    }
    if (state.currency === "RWF") {
      return setState({
        ...state,
        formError: "Currency RWF is not supported by PayPal",
      });
    }
    return onPaymentSubmit({
      email: state.email,
      phoneNumber: state.phoneNumber,
      amount: state.amount,
      currency: state.currency,
    });
  };
  useEffect(() => {
    if (error || completed) {
      setState({
        currency: paymentOptions.currency[0],
        options: paymentOptions.options[paymentOptions.currency[0]],
      });
    }
  }, [error, completed]);
  return (
    <Box className={classes.root}>
      {completed && !error ? (
        <Box
          style={{
            textAlign: "center",
            width: "100%",
          }}
        >
          <Typography variant="subtitle1">
            Your payment is being processed, we will notify you once done.
          </Typography>
          <CircularProgress
            style={{
              display: "inline-block",
              padding: 30,
              color: "#1876D1",
            }}
          />
        </Box>
      ) : showPaypal ? (
        <PayPalButtons
          createOrder={(data, actions) =>
            actions.order.create({
              purchase_units: [
                {
                  custom_id: tx_ref,
                  reference_id: tx_ref,
                  description,
                  amount: {
                    value: state.amount,
                    currency_code: state.currency,
                  },
                },
              ],
            })
          }
          onApprove={(data, actions) =>
            actions.order.capture().then((details) => {
              onApprovePaypalOrder();
            })
          }
          onCancel={(data) => {
            onCancelPaypalOrder();
          }}
          onError={(err) => {
            onErrorPaypalOrder();
          }}
        />
      ) : (
        <>
          <Box className={classes.inputElt}>
            <TextField
              id="email"
              label="Email"
              name="email"
              placeholder="Enter your email"
              onChange={onInputChange}
              value={state.email.trim() || ""}
            />
          </Box>

          <Box className={classes.groupInputElt}>
            <Select
              id="currency"
              value={state.currency}
              name="currency"
              onChange={onInputChange}
            >
              {paymentOptions.currency.map((elt) => (
                <MenuItem key={elt} value={elt}>
                  {elt}
                </MenuItem>
              ))}
            </Select>

            <FormControl fullWidth>
              <InputLabel id="amount-label">Option</InputLabel>
              <Select
                labelId="amount-label"
                id="amount"
                value={state.amount || ""}
                label="Amount"
                name="amount"
                onChange={onAmountChoosing}
              >
                {state.options.map((option) => (
                  <MenuItem key={option.amount} value={option.amount}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Typography className={classes.errorMsg} variant="subtitle1">
            {state.formError || (error && message)}
          </Typography>

          <SubmitButton
            onClick={onPayment}
            loading={loading}
            disabled={loading}
          >
            Proceed With Payment
          </SubmitButton>
        </>
      )}
    </Box>
  );
};
