import React from "react";
import { useParams } from "react-router-dom";
import AppLayout from "../../layouts/AppLayout";
import PreviewTicket from "../containers/events/PreviewTicket";

const TicketPreview = ()  => {
  const params = useParams();
  const {eventId} = params;
  return(
      <AppLayout>
        <PreviewTicket eventId={eventId}/>
      </AppLayout>
    )}

export default TicketPreview;