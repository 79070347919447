import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { makeStyles } from "@mui/styles";
import SubmitButton from "../../../components/SubmitButton";

const useStyles = makeStyles(() => ({
  root: {
    padding: "30px 0",
    "@media (max-width: 600px)": {
      padding: "10px 2%",
    },
  },
  inputElt: {
    padding: "10px 0",
    width: "100%",
    display: "grid",
  },
  groupInputElt: {
    padding: "10px 0",
    display: "grid",
    gridGap: "10px",
    gridTemplateColumns: "repeat(2,1fr)",
    "@media (max-width: 600px)": {
      gridTemplateColumns: "repeat(1,1fr)",
      padding: "5px 0",
    },
  },
  selectTitle: {
    color: "grey",
  },
  submitBtn: {
    padding: "15px 0",
    width: "50%",
    backgroundColor: "#1876D1",
    textAlign: "center",

    "&:hover": {
      background: "#1876D1",
      cursor: "pointer",
    },
    "@media (max-width: 600px)": {
      padding: "10px 0",
      width: "100%",
    },
  },
  errorMsg: {
    color: "red",
  },
}));

export default ({
  showOTP,
  paymentOptions = [],
  message = "",
  onPaymentSubmit,
  onOTPSubmit,
  loading = false,
  error,
  completed,
}) => {
  const classes = useStyles();
  const [state, setState] = useState({
    otp: null,
    phoneNumber: null,
  });

  const onPhoneChange = (phone) => {
    setState({
      ...state,
      phoneNumber: phone,
      formError: "",
    });
  };

  const onAmountChoosing = (e) => {
    const { value: amount } = e.target;
    setState({
      ...state,
      amount,
      formError: "",
    });
  };

  const onInputChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
      formError: "",
    });
  };

  const onPayment = () => {
    if (!state.amount || !state.phoneNumber || state.phoneNumber.length < 12) {
      return setState({
        ...state,
        formError: "Please enter correct phone number and select amount",
      });
    }
    return onPaymentSubmit({
      email: undefined,
      phoneNumber: state.phoneNumber,
      amount: state.amount,
      currency: "RWF",
    });
  };

  const onSubmitOtp = () => {
    onOTPSubmit(state.otp.replaceAll("\\s+", ""));
  };

  useEffect(() => {
    if (error || completed) {
      setState({
        otp: null,
        phoneNumber: null,
      });
    }
  }, [error, completed]);

  return (
    <Box>
      {showOTP ? (
        <Box className={classes.inputElt}>
          <Typography variant="subtitle1">
            Please enter the MOMO validation OTP sent to you via SMS and
            Whatsapp to complete this transaction.
          </Typography>
          <br></br>
          <TextField
            id="otp"
            label="OTP"
            placeholder="Enter your OTP"
            name="otp"
            onChange={onInputChange}
            value={state.otp}
          />
          <br></br>
          <Typography variant="subtitle1" color="#1876D1">
            {error && message}
          </Typography>
          <SubmitButton
            onClick={onSubmitOtp}
            loading={loading}
            disabled={loading}
          >
            Send OTP
          </SubmitButton>
        </Box>
      ) : completed ? (
        <Box
          style={{
            textAlign: "center",
            width: "100%",
          }}
        >
          <Typography variant="subtitle1">
            Complete payment on your phone to be proccessed, we will notify you
            once done. If you do not see the prompt dial *182*7*1#
          </Typography>
          <CircularProgress
            style={{
              display: "inline-block",
              padding: 30,
              color: "#1876D1",
            }}
          />
        </Box>
      ) : (
        <React.Fragment>
          <PhoneInput
            country={"rw"}
            className={classes.inputElt}
            value={state.phone}
            onChange={onPhoneChange}
            inputStyle={{ width: "100%" }}
          />
          <br></br>
          <Box className={classes.inputElt}>
            <FormControl fullWidth>
              <InputLabel id="amount-label">Options</InputLabel>
              <Select
                labelId="amount-label"
                id="amount"
                value={state.amount || ""}
                label="Payment options"
                name="amount"
                onChange={onAmountChoosing}
                placeholder="Enter amount"
              >
                {paymentOptions.map((elt) => (
                  <MenuItem key={elt} value={elt.amount}>
                    {elt.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br></br>
            <Typography className={classes.errorMsg} variant="subtitle1">
              {state.formError || (error && message)}
            </Typography>
          </Box>
          <SubmitButton
            onClick={onPayment}
            loading={loading}
            disabled={loading}
          >
            Proceed With Payment
          </SubmitButton>
        </React.Fragment>
      )}
    </Box>
  );
};
