import React, { Component } from "react";
import { Container } from "@mui/material";
import AppLayout from "../../layouts/AppLayout";

export class TermsAndConditions extends Component {
  // eslint-disable-next-line class-methods-use-this
  render() {
    const title = "Terms & Conditions";

    return (
      <AppLayout>
        <Container>
          <h1>{title}</h1>
          <div>
            <p>
              Welcome to our website! These terms and conditions govern your use
              of our website for payment-based voting and purchasing concert
              event tickets. Please read these Terms carefully before using our
              website. By using our website, you agree to be bound by these
              Terms. If you do not agree with any part of these Terms, please
              refrain from using our website.
            </p>
            <br></br>
            <ol>
              <li>
                <b>General Information</b>
                <br></br>
                1.1 The website, located at{" "}
                <a href="https://noneho.com">https://noneho.com</a>, is owned
                and operated by Inyarwanda Ltd, hereinafter referred to as
                &quot;we,&quot; &quot;us,&quot; or &quot;our.&quot;
                <br></br>
                1.2 By using our website, you confirm that you are at least 18
                years old or have the necessary legal capacity to enter into a
                contract.
              </li>
              <br></br>
              <li>
                <b> User Information</b>
                <br></br>
                2.1 To process payments on our website, we require certain user
                information, including but not limited to your first name, last
                name, email address, and phone number.<br></br>
                2.2 The user information collected is used solely for payment
                processing purposes and will not be shared with third parties,
                except as required by law or as necessary to fulfill our
                services.
              </li>
              <br></br>
              <li>
                <b>Voting for Competitions and Selections</b>
                <br></br>
                3.1 Our website allows users to vote for candidates in
                competitions and selections by making payments for votes.
                <br></br>
                3.2 Upon completing the payment for votes, the vote count is
                updated immediately. However, in some cases, it may take up to
                an hour for the vote count to be reflected.<br></br>
                3.3 If you do not see the updated vote count after the specified
                time, please contact our support team for assistance.<br></br>
                3.4 We reserve the right to cancel or void votes in the event of
                suspected fraudulent activities or violations of these Terms.
              </li>
              <br></br>
              <li>
                <b>Purchasing Concert Event Tickets</b>
                <br></br>
                4.1 Our website offers the purchase of concert event tickets.
                <br></br>
                4.2 After completing the payment for a ticket, the ticket will
                be delivered to you through one of the following methods: email,
                WhatsApp, or direct download. <br></br>4.3 It is your
                responsibility to provide accurate contact information to ensure
                the successful delivery of the ticket.<br></br> 4.4 If you do
                not receive your ticket within a reasonable timeframe, please
                contact our support team for assistance.
              </li>
              <br></br>
              <li>
                <b>Intellectual Property</b>
                <br></br>
                5.1 All content on our website, including but not limited to
                text, graphics, logos, images, audio clips, and software, is the
                property of Inyarwanda Ltd or its licensors and is protected by
                intellectual property laws.<br></br> 5.2 You may not reproduce,
                distribute, modify, transmit, display, perform, or use any
                content from our website without our prior written consent.
              </li>
              <br></br>
              <li>
                <b>Limitation of Liability</b>
                <br></br>
                6.1 While we strive to provide accurate and reliable information
                on our website, we cannot guarantee the absolute accuracy,
                completeness, or reliability of all content or information
                provided. We make every effort to ensure the highest quality and
                reliability of our services.<br></br> 6.2 We shall not be held
                liable for any direct, indirect, incidental, special, or
                consequential damages arising out of or in connection with your
                use of our website or the services provided. However, we will
                take reasonable measures to address any inaccuracies or errors
                promptly upon notification.<br></br> 6.3 We encourage users to
                notify us of any concerns or inaccuracies they encounter on our
                website. We value your feedback and will make every effort to
                rectify any issues promptly. Your satisfaction and trust are
                important to us.<br></br><br></br> Please note that while we strive to
                provide a seamless and reliable experience, it is important to
                remain vigilant and take necessary precautions when using our
                website or engaging in transactions. We recommend reviewing all
                information carefully and contacting our support team should you
                have any questions or encounter any difficulties.<br></br><br></br> Rest
                assured, we are committed to providing you with the best
                possible service and ensuring your satisfaction throughout your
                experience on our website.
              </li>
              <br></br>
              <li>
                <b>Support and Contact Information</b>
                <br></br>
                7.1 For any questions, concerns, or support related to our
                website, please contact our support team using the following
                details:
                <ul style={{ paddingLeft: 40 }}>
                  <li>Telephone: +250788244702</li>
                  <li>Email: info@inyarwanda.com</li>
                </ul>
              </li>
              <br></br>
              <li>
                <b>Modification of Terms</b>
                <br></br>
                8.1 We reserve the right to modify or update these Terms at any
                time and give notice. Any changes to these Terms will be
                effective immediately upon posting on our website.<br></br> 8.2
                It is your responsibility to review these Terms periodically to
                stay informed of any updates.
              </li>
              <br></br>
            </ol>
          </div>
          <p>
            By using our website, you acknowledge that you have read,
            understood, and agree to be bound by these Terms and any additional
            terms and conditions that may apply. If you do not agree with these
            Terms, please discontinue using our website.
          </p>
          <br></br>
          <br></br>
          <h6>Last updated: Thu 25th, May 2023</h6>
        </Container>
      </AppLayout>
    );
  }
}

export default TermsAndConditions;
