import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
import { primaryColor } from "../../assets/styles/base";

export default (props) => (
  <div>
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ textTransform: "capitalize" }}
      >
        {props.title}
        {props.loading ? <LinearProgress variant="indeterminate" /> : null}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={props.loading}
          onClick={props.onConfirm}
          color="primary"
          autoFocus
          style={{
            background: primaryColor[0],
          }}
          variant="contained"
        >
          {props.confirmText || "Confirm"}
        </Button>
        <Button
          onClick={props.onCancel}
          color="inherit"
          variant="contained"
          disabled={props.loading}
        >
          No
        </Button>

      </DialogActions>
    </Dialog>
  </div>
);
